import React from "react"
import { AnimatePresence } from "framer-motion"

export const wrapPageElement = ({ element }) => (
  <AnimatePresence
    exitBeforeEnter
    initial={false}
    onExitComplete={() => window.scrollTo(0, 0)}
  >
    {element}
  </AnimatePresence>
)

export const shouldUpdateScroll = () => false
